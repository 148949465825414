const dev = {
  apiUrl: 'https://api.meindl-entsorgung.de/',
  version: '0.0.16'
};
const prod = {
  apiUrl: 'https://api.meindl-entsorgung.de/',
  version: '1.2.0'
};

let config = prod;

if (process.env.NODE_ENV === 'production') {
  config = prod;
}



export default {
  // Add common config values here
  ...config
};
